/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import {
  MetaFunction,
  json,
  LoaderFunctionArgs,
  ActionFunctionArgs,
} from "@remix-run/node";
import { Form, useLoaderData, useNavigation, Link } from "@remix-run/react";
import { authenticator, EMAIL_PASSWORD_STRATEGY } from "~/server/auth.server";
import { getSession, commitSession } from "~/server/session.server";
import { useToast } from "~/hooks/use-toast";
import { AppRoutes } from "~/lib/routes";
import PanMedicalLogo from '~/images/medlink/panmed.png';
import LoginBgClient from '~/images/medlink/login-bg-client.jpg';
import LoginBgInsurance from '~/images/medlink/login-bg-insurance.webp';

// Shadcn component imports
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import { Checkbox } from "~/components/ui/checkbox";

// Ovely icon imports  
import { EnvelopeClosedIcon, LockClosedIcon } from "@radix-ui/react-icons";
import { ProgressLoader } from "~/components/progress-loader";
import { cn } from "~/lib/utils";

export const meta: MetaFunction = () => {
  return [{ title: "Login | Medlink" }];
};

export default function Login() {
  const loaderData = useLoaderData<any>();
  const navigation = useNavigation();
  const { toast } = useToast();
  const error = loaderData?.error;
  const message = loaderData?.message;
  const loginType = loaderData?.loginType;
  useEffect(() => {
    if (error?.message) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
    if (message) {
      toast({
        title: "Notice",
        description: message,
        variant: "default",
      });
    }
  }, [error?.message, message, toast]);

  return (
    <>
      <ProgressLoader />
      <div className="min-h-screen flex flex-col md:flex-row">
        {/* Left Panel - Hero/Background Section */}
        <div className="hidden md:flex md:w-1/2 relative">
          <div className="absolute inset-0">
            <img 
              src={loginType === "insurance" ? LoginBgInsurance : LoginBgClient} 
              alt="Healthcare Background" 
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-primary/90 to-primary/40 mix-blend-multiply" />
          </div>
          <div className="relative z-10 p-12 flex flex-col justify-between w-full">
            {/* Top Content */}
            <div>
              <Link to={AppRoutes.Index} viewTransition>
                <img 
                  src={PanMedicalLogo} 
                  alt="Pan Medical Logo" 
                  className="w-64 mb-8"
                />
              </Link>
              <h1 className="text-4xl font-bold text-white mb-4">
                {loginType === "insurance" ? "Welcome to Medlink" : "Welcome to Pan Medical"}
              </h1>
              <p className="text-white/90 text-lg max-w-md">
                Your trusted partner in healthcare management and services
              </p>
            </div>
            
            {/* Bottom Content */}
            <div className="space-y-2 text-center">
              <p className="text-white/70 text-sm">
                © {new Date().getFullYear()} Medlink. All rights reserved.
              </p>
              <p className="text-white/50 text-xs">
                A product of Ctrlv Solutions, Ltd.
              </p>
            </div>
          </div>
        </div>

        {/* Right Panel - Login Form */}
        <div className="flex-1 flex items-center justify-center p-6 bg-background">
          <div className="w-full max-w-md space-y-8">
            {/* Mobile Logo - Only visible on mobile */}
            <div className="md:hidden flex justify-center mb-8">
              <Link to={AppRoutes.Index} viewTransition>
                <img 
                  src={PanMedicalLogo} 
                  alt="Pan Medical Logo" 
                  className="w-24"
                />
              </Link>
            </div>

            <Card className="border-border/50">
              <CardHeader className="space-y-2">
                <CardTitle className="text-2xl font-bold text-center">
                  Sign In
                </CardTitle>
                <CardDescription className="text-center">
                  Access your healthcare dashboard
                </CardDescription>
              </CardHeader>

              <CardContent>
                {error && (
                  <Alert variant="destructive" className="mb-4">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error.message || message}</AlertDescription>
                  </Alert>
                )}

                <Form method="post" className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="username">
                      {loginType === "insurance" ? "Email address" : "Client number"}
                    </Label>
                    <div className="relative">
                      <EnvelopeClosedIcon className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground" />
                      <Input
                        id="username"
                        name="username"
                        type={loginType === "insurance" ? "email" : "text"}
                        autoComplete="username"
                        required
                        className="pl-10"
                        placeholder={loginType === "insurance" ? "Enter your email" : "Enter your client number"}
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="password">Password</Label>
                    <div className="relative">
                      <LockClosedIcon className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground" />
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="pl-10"
                        placeholder="Enter your password"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <Checkbox id="remember-me" />
                      <Label htmlFor="remember-me" className="text-sm">
                        Remember me
                      </Label>
                    </div>
                    <Button variant="link" className="text-sm">
                      Forgot password?
                    </Button>
                  </div>

                  <Button
                    type="submit"
                    className="w-full"
                    disabled={navigation.state === "submitting"}
                  >
                    {navigation.state === "submitting" ? "Signing in..." : "Sign in"}
                  </Button>
                </Form>
              </CardContent>

              <CardFooter className={cn("justify-center", loginType === "insurance" && "hidden")}>
                <p className="text-sm text-muted-foreground">
                  Don&apos;t have an account?{" "}
                  <Link to="/signup" viewTransition>
                    <Button variant="link" className="p-0">
                      {navigation.state === "loading" ? "Loading..." : "Sign up"}
                    </Button>
                  </Link>
                </p>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  return await authenticator.authenticate(EMAIL_PASSWORD_STRATEGY, request, {
    successRedirect: AppRoutes.Profile.Select,
    failureRedirect: AppRoutes.Auth.Login,
  });
}

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const loginType = url.searchParams.get('type');


  let message = null;
  if (loginType === 'insurance_required') {
    message = "You must have an insurance profile to access this page. Please log in with an insurance account.";
  }

  await authenticator.isAuthenticated(request, {
    successRedirect: AppRoutes.Dashboard.Index,
  });

  const session = await getSession(request.headers.get("cookie"));
  const error = session.get(authenticator.sessionErrorKey);

  // Clear the error from the session
  session.unset(authenticator.sessionErrorKey);

  return json(
    { error, message, loginType },
    {
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    }
  );
}
